import React, { useState, useEffect } from 'react';

export const CommentaryModal = ({ closeModal, submitComment, commentary }) => {
    const [comment, setComment] = useState(''); // Estado para o texto do comentário


    useEffect(() => {
        if (commentary) {
            setComment(commentary);
        }
    }, [commentary]);

    const handleSubmit = () => {
        submitComment(comment); 
        setComment('');
        closeModal();
    };

    return (
        <div style={styles.modalOverlay}>
            <div style={styles.modalContainer}>
                <h2>Adicionar Comentário</h2>
                <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Digite seu comentário aqui..."
                    style={styles.textArea}
                />
                <div style={styles.buttonContainer}>
                    <button onClick={closeModal} style={styles.buttonClose}>
                        Cancelar
                    </button>
                    <button onClick={handleSubmit} style={styles.buttonSubmit}>
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContainer: {
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    textArea: {
        width: '100%',
        height: '100px',
        marginBottom: '10px',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonClose: {
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '10px 20px',
        cursor: 'pointer',
    },
    buttonSubmit: {
        backgroundColor: '#4CAF50',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '10px 20px',
        cursor: 'pointer',
    },
};
